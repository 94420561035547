import React from 'react';
import { useTranslation } from 'react-i18next';
import css from './PopUp.module.css';
import { changeLanguage } from 'i18next';
import i18n from '../../i18n';

interface PopUpProps {
  onProceed: () => void;
}

const PopUp: React.FC<PopUpProps> = ({ onProceed }) => {
  const { t } = useTranslation();
  let currentLanguage = localStorage.getItem('language') || 'en';

  const changeLanguage = () => {
    const newLang = i18n.language === 'ru' ? 'en' : 'ru';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  return (
    <div className={css.overlay}>

      <div className={css.popup}>
      <button onClick={changeLanguage} className="button-lang_popup">
        <span>{t('change_language')}</span>
        <span>( {currentLanguage} )</span>
      </button>
        <h3 className={css.title}>{t('popup.title')}</h3>
        <p className={css.description}>{t('popup.description1')}</p>
        <p className={css.description}>{t('popup.description2')}</p>
        <button className={css.button} onClick={onProceed}>
          {t('popup.proceed')}
        </button>
      </div>
    </div>
  );
};

export default PopUp;
