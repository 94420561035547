import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import css from './header.module.css';
import { PaymentContext } from '../wallet/PaymentContext';

const Header: FC = () => {
  const { t } = useTranslation();
  const { amount, paymentIdUrl } = useContext(PaymentContext);

  function getNumericOrderId(uuid: string): string {
    const numericId = uuid.replace(/[^\d]/g, '');
    return numericId.slice(0, 9);
  }

  return (
    <header className={css.header}>
      <div className={css.textWrapper}>
        <p className={css.title}>{t('order_number')}</p>
        <p className={css.info}>#{getNumericOrderId(paymentIdUrl)}</p>
      </div>
      <div className={css.textWrapper}>
        <p className={css.title}>{t('payment')}</p>
        <p className={css.info}>{amount} USDT</p>
      </div>
    </header>
  );
};

export default Header;
