import {
  FC,
  useEffect,
  useState,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import css from './footer.module.css';
import cn from 'classnames';
import SuccesIcon from '../icons/succes-icon.component';
import FalledIcon from '../icons/falled-icon.component';
import CountdownTimer from '../countdownTimer/countdownTimer.component';
import PopUp from '../popup/Popup';
import { PaymentContext } from '../wallet/PaymentContext';

type FooterType = '' | 'regular' | 'success' | 'failed';

interface FooterProps {
  setOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}

const Footer: FC<FooterProps> = ({ setOverlay }) => {
  const { t } = useTranslation();
  const [type, setType] = useState<FooterType>('');
  const [paymentId, setPaymentId] = useState('');
  const [isPopUpVisible, setIsPopUpVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const { setWallet, setAmount, paymentIdUrl, setPaymentIdUrl } =
    useContext(PaymentContext);

  const handleProceed = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + '/start',
        {
          paymentId: paymentIdUrl,
        }
      );

      if (response.status === 200) {
        const {
          paymentId,
          amount,
          wallet: responseWallet,
          error,
        } = response.data;

        if (error) {
          alert(`${t('footer.error')}: ${error}`);
          setType('failed');
          return;
        }

        setPaymentId(paymentId);
        setAmount(amount);
        setWallet(responseWallet);
        setIsPopUpVisible(false);
        setType('regular');

        console.log('Payment created:', paymentId, amount, responseWallet);
      } else {
        setType('failed');
      }
    } catch (error: any) {
      console.error('Error creating payment:', error);
      alert(`${t('footer.error')}: ${error.response?.data?.error || error.message}`);
      setType('failed');
    } finally {
      setIsLoading(false);
    }
  };

  const footerClass = cn(css.wrap, {
    [css.regular]: type === 'regular',
    [css.big]: type !== 'regular',
    [css.success]: type === 'success',
    [css.falled]: type === 'failed',
  });

  const checkStatus = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/status?paymentId=${paymentId}`
      );

      if (response.data.status === 'paid') {
        setType('success');
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
          intervalIdRef.current = null;
        }
      }
    } catch (error) {
      console.error('Error checking payment status:', error);
    }
  }, [paymentId]);

  const handleTimeUp = useCallback(() => {
    setType('failed');
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  }, []);

  useEffect(() => {
    const getIdFromUrl = () => {
      const currentUrl = window.location.pathname;
      const hash = currentUrl.replace('/', '');
      console.log('PaymentIdUrl', hash);
      setPaymentIdUrl(hash);
    };

    getIdFromUrl();

    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }

    if (paymentId && type !== 'success' && type !== 'failed') {
      intervalIdRef.current = setInterval(() => {
        checkStatus();
      }, 20000);
      console.log('Request interval set to 20 seconds');
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [paymentId, type, checkStatus, setPaymentIdUrl]);

  const renderFooterContent = () => {
    switch (type) {
      case 'regular':
        return (
          <>
            <div className={css.timeWrap}>
              <span className={css.title}>{t('footer.until_closes')}</span>
              <CountdownTimer initialTime={'20:00'} onTimeUp={handleTimeUp} />
            </div>
          </>
        );
      case 'success':
        return (
          <>
            <SuccesIcon />
            <span className={css.description}>{t('footer.success')}</span>
          </>
        );
      case 'failed':
        return (
          <>
            <FalledIcon />
            <br />
            <br />
            <span className={css.description}>{t('footer.expired')}</span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {isLoading && (
        <div className={css.overlay}>
          <div className={css.loader}></div>
        </div>
      )}
      <footer className={footerClass}>
        {renderFooterContent()}
        {isPopUpVisible && <PopUp onProceed={handleProceed} />}
      </footer>
    </div>
  );
};

export default Footer;
