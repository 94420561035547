import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const urlParams = new URLSearchParams(window.location.search);
const urlLanguage = urlParams.get('lng');

const supportedLanguages = ['ru', 'en'];

const validLanguage = supportedLanguages.includes(urlLanguage || '') ? urlLanguage : 'en';

if (urlLanguage) {
  localStorage.setItem('language', validLanguage!);
}

const savedLanguage = validLanguage || localStorage.getItem('language') || 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('./locales/en.json') },
      ru: { translation: require('./locales/ru.json') },
    },
    lng: savedLanguage, 
    fallbackLng: 'en', 
    interpolation: { escapeValue: false },
  });

export default i18n;
