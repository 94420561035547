import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import qr from './qr.png';
import css from './main.module.css';
import Button from '../button/button.component';
import { PaymentContext } from '../wallet/PaymentContext';

const Main: FC = () => {
  const { t } = useTranslation();
  const { wallet } = useContext(PaymentContext);

  const copyTextToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert(t('copy_success'));
    } catch (err) {
      console.error('Error:', err);
      alert(t('copy_error'));
    }
  };

  return (
    <div className={css.wrapper}>
      <div className={css.qrWrapper}>
        <div className={css.label}>
          <span>{t('usdt_trc20')}</span>
        </div>
        <img src={qr} className={css.qr} alt={t('qr_code')} />
      </div>
      <div className={css.instructionWrapper}>
        <span>{t('scan_qr')}</span>
        <div className={css.blockOr}>
          <div className={css.line} />
          <span>{t('or')}</span>
          <div className={css.line} />
        </div>
        <span>{t('transfer_to_address')}</span>
        <Button
          onClick={() => copyTextToClipboard(wallet)}
          copy={true}
          value={wallet}
        />
      </div>
    </div>
  );
};

export default Main;
