import './App.css';
import MainPage from './components/pages/mainPage.component';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();
  let currentLanguage = localStorage.getItem('language') || 'en';

  const changeLanguage = () => {
    const newLang = i18n.language === 'ru' ? 'en' : 'ru';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  return (
    <div className="App">
      <button onClick={changeLanguage} className="button-lang">
        <span>{t('change_language')}</span>
        <span>( {currentLanguage} )</span>
      </button>
      <MainPage />
    </div>
  );
}

export default App;
